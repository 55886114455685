<template>
  <div>
    <div>
      <a-card>
        <div class="table-operator">
          <a-form
            v-if="this.viewHasRight === true"
            layout="inline"
            class="feeEditLabelWidth1"
          >
            <a-row :gutter="5" style="line-height: 3.5">
              <a-col :md="7" :sm="21">
                <a-form-item label="客户名称">
                  <a-input
                    v-model="queryParam.name"
                    placeholder="请输入"
                    @pressEnter="select"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="7" :sm="21">
                <a-form-item label="合作状态">
                  <a-select
                    style="width: 175px"
                    v-model="queryParam.coopStatus"
                    @change="select"
                    allowClear
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in coopStatusList"
                      :key="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="7" :sm="21">
                <a-form-item label="客户等级">
                  <a-select
                    style="width: 175px"
                    v-model="queryParam.tenantLevel"
                    @change="select"
                    allowClear
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in tenantLevelList"
                      :key="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="7" :sm="21">
                <a-form-item label="客户类型">
                  <a-select
                    style="width: 175px"
                    v-model="queryParam.tenantType"
                    @change="select"
                    allowClear
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in tenantTypeList"
                      :key="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="7" :sm="21">
                <a-form-item label="客户ID">
                  <a-input
                    style="width: 175px"
                    v-model="queryParam.code"
                    @pressEnter="select"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="(!advanced && 7) || 21" :sm="21">
                <span
                  class="table-page-search-submitButtons"
                  :style="
                    (advanced && { float: 'right', overflow: 'hidden' }) || {}
                  "
                >
                  <!--                  <a-button type="primary" @click="select">查询</a-button>-->
                  <a-button
                    style="margin-left: 8px"
                    @click="() => ((queryParam = {}), this.init())"
                    >重置</a-button
                  >
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <a-table
          rowKey="id"
          :pagination="pagination"
          :columns="columns"
          :dataSource="data"
          :scroll="{ x: 800 }"
          @change="changePageSize"
        >
          <span slot="tenantLevel" slot-scope="text, record">
            <template>
              <span>{{ showTenantLevel(record.tenantLevel) }}</span>
            </template>
          </span>
          <span slot="tenantType" slot-scope="text, record">
            <template>
              <span>{{ showTenantType(record.tenantType) }}</span>
            </template>
          </span>
          <span slot="action" slot-scope="text, record" class="blueColor">
            <template>
              <a v-if="viewHasRight === true" @click="handleDetail(record)"
                >查看&nbsp;&nbsp;</a
              >
              <a v-if="editHasRight === true" @click="handleEdit(record)"
                >编辑&nbsp;&nbsp;</a
              >
              <a @click="handleActive(record)">操作日志</a>
            </template>
          </span>
        </a-table>
        <a-modal
          title="操作日志"
          width="700px"
          v-model="active"
          @ok="handleOk"
          :okButtonProps="{ props: { disabled: true } }"
          :cancelButtonProps="{ props: { disabled: true } }"
          footer
          @change="changePageActionSize"
        >
          <a-table
            rowKey="id"
            :pagination="paginationaction"
            :columns="actioncolumns"
            :dataSource="actiondata"
          >
            <span slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
          </a-table>
        </a-modal>
      </a-card>
    </div>
  </div>
</template>

<script>
  
import Bus from "../../bus";
import moment from "moment";
import { defaultPagination } from "../../utils/pagination";
import axios from "axios";
import { mapGetters } from "vuex";
import { hasPermissions } from "../../utils/auth";
import { OrganizationActions } from "../../utils/actions";

export default {
  name: "RoleList",
  components: {},
  data() {
    return {
      data: [],
      record: "",
      advanced: "",
      showDetail: false,
      editDetail: false,
      active: false,
      roleListDetail: "",
      roleListEdit: "",
      queryParam: {
        brandName: "",
        code: "",
        status: "",
        name: ""
      },
      actiondata: [],
      paginationaction: defaultPagination(() => {}),
      pagination: defaultPagination(() => {}),
      coopStatusList: [
        { id: 1, name: "未合作" },
        { id: 2, name: "已合作" }
      ],
      tenantLevelList: [
        { id: 1, name: "中小商户" },
        { id: 2, name: "战略客户" }
      ],
      tenantTypeList: [
        { id: 1, name: "个体商户" },
        { id: 2, name: "连锁加盟" },
        { id: 3, name: "品牌直营" }
      ],
      // 表头
      columns: [
        {
          title: "客户名称",
          dataIndex: "name",
          key: "name",
          fixed: "left"
        },
        {
          title: "客户ID",
          dataIndex: "code",
          key: "code"
        },
        {
          title: "经营品类",
          dataIndex: "cuisineType",
          key: "cuisineType"
        },
        {
          title: "客户等级",
          dataIndex: "tenantLevel",
          key: "tenantLevel",
          scopedSlots: { customRender: "tenantLevel" }
        },
        {
          title: "客户类型",
          dataIndex: "tenantType",
          key: "tenantType",
          scopedSlots: { customRender: "tenantType" }
        },
        {
          title: "合作状态",
          dataIndex: "coopStatus",
          key: "coopStatus"
        },
        {
          title: "操作",
          // dataIndex: "action",
          width: "200px",
          scopedSlots: { customRender: "action" },
          fixed: "right"
        }
      ],
      //actioncolumns
      actioncolumns: [
        {
          title: "操作人",
          dataIndex: "operator",
          key: "operator"
        },
        {
          title: "操作时间",
          dataIndex: "operationTime",
          key: "operationTime",
          width: "130px"
        },
        {
          title: "操作内容",
          dataIndex: "content",
          key: "content"
        }
      ],
      actionId: "",
      roleList: [
        { id: 1, value: "预订中" },
        { id: 2, value: "已预订" },
        { id: 3, value: "签约中" },
        { id: 4, value: "已签约" },
        { id: 5, value: "改造中" },
        { id: 6, value: "待入驻" },
        { id: 7, value: "已入驻" },
        { id: 8, value: "申请退租中" },
        { id: 9, value: "退租中" },
        { id: 10, value: "已退租" },
        { id: 11, value: "已离场" }
      ],
      form: this.$form.createForm(this)
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.init();
    });
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    viewHasRight() {
      return hasPermissions(
        [OrganizationActions.CLIENT_READ],
        this.authenticatedUser.actions
      );
    },
    editHasRight() {
      return hasPermissions(
        [OrganizationActions.CLIENT_EDIT],
        this.authenticatedUser.actions
      );
    },
    showTenantLevel(val) {
      let a = "";
      return function(val) {
        this.tenantLevelList.forEach(item => {
          if (item.id == val) {
            a = item.name;
          }
        });
        return a;
      };
    },
    showTenantType(val) {
      let a = "";
      return function(val) {
        this.tenantTypeList.forEach(item => {
          if (item.id == val) {
            a = item.name;
          }
        });
        return a;
      };
    }
  },
  methods: {
    moment,
    changePageSize(val) {
      this.init(val.current, val.pageSize);
    },
    init(current, pageSize) {
      axios({
        url:   this.$baseUrl + "/tenant/list",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            item.status = item.status == 1 ? "合作中" : "未合作";
            item.cuisineType = this.cuisineTypeList(item.cuisineType);
          });
          this.data = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
        // console.log(res);
      });
    },
    cuisineTypeList(val) {
      // alert(val);
      if (val != undefined && val != null) {
        switch (val) {
          case 0:
            val = " ";
            break;
          case 1:
            val = "粤菜";
            break;
          case 2:
            val = "川湘菜";
            break;
          case 3:
            val = "江浙菜";
            break;
          case 4:
            val = "快餐便当";
            break;
          case 5:
            val = "简餐沙拉";
            break;
          case 6:
            val = "米粉面馆";
            break;
          case 7:
            val = "饺子馄饨";
            break;
          case 8:
            val = "包子粥店";
            break;
          case 9:
            val = "生煎锅贴";
            break;
          case 10:
            val = "小吃烧烤";
            break;
          case 11:
            val = "香锅干锅";
            break;
          case 12:
            val = "海鲜龙虾";
            break;
          case 13:
            val = "砂锅汤类";
            break;
          case 14:
            val = "火锅";
            break;
          case 15:
            val = "西餐";
            break;
          case 16:
            val = "东南亚菜";
            break;
          case 17:
            val = "日韩菜";
            break;
          case 18:
            val = "意面披萨";
            break;
          case 19:
            val = "汉堡薯条";
            break;
          case 20:
            val = "其他地方菜系";
            break;
          case 21:
            val = "甜品饮品";
            break;
          case 22:
            val = "果蔬生鲜";
            break;
          case 23:
            val = "鲜花绿植";
            break;
          case 24:
            val = "医药健康";
            break;
          case 25:
            val = "商品超市";
            break;
          case 26:
            val = "服装鞋帽";
            break;
          case 27:
            val = "美妆";
            break;
          default:
            val = "无";
        }
      }
      return val;
    },
    handleDetail(val) {
      this.$router.push({
        path: "/role/RoleList/roleListDetail",
        query: { roleListDetailId: val.id, roleListBrandId: val.brandId }
      });
    },
    handleEdit(val) {
      this.$router.push({
        path: "/role/RoleList/roleListEdit",
        query: { roleListEditId: val.id }
      });
    },
    handleActive(val) {
      this.actionId = val;
      this.handleActivecha();
      this.active = true;
    },
    handleActivecha(current, pageSize) {
      axios({
        url:   this.$baseUrl + "tenant/listTenantLog",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          tenantId: this.actionId.id
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            if (item.operationTime) {
              item.operationTime = moment(item.operationTime).format(
                "YYYY-MM-DD"
              );
            }
          });
          this.actiondata = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.paginationaction = pagination;
        }
      });
    },
    changePageActionSize(val) {
      this.handleActivecha(val.current, val.pageSize);
    },
    select() {
      this.init();
    },
    handleOk() {},
    cancelRole() {
      // this.editDetail = false;
      // this.showDetail = false;
    }
  }
};
</script>

<style scoped>
.table-operator {
  margin-bottom: 18px;
}
</style>
